const config = {
  initiateLoginEndpoint: "https://teams.easycallreport.easyplatform.app/auth-start.html",
  clientId: 'c42b0067-bfb5-4a33-8f9f-5f1ef2dfceb8',
  apiEndpoint: 'https://api.easyplatform.app/',
  graphEndpint: 'https://graph.microsoft.com',
  adminLoginUrl: 'https://admin.easyplatform.app/',
  teamsAppUrl: 'https://teams.easycallreport.easyplatform.app',
  scopes: [ 'api://teams.easycallreport.easyplatform.app/c42b0067-bfb5-4a33-8f9f-5f1ef2dfceb8/access_as_user' ],
  userScopes: ["User.Read", "Presence.Read.All", "User.Read.All"]
};

  
export default config;
